import { PatientIndexSearchResult } from "@aspire/common";
import { Stack } from "@mui/material";
import { useDialogs } from "@toolpad/core/useDialogs";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "~/api.js";
import { LoggedInUserContext } from "~/Contexts.js";
import { routeFns } from "~/routes.js";
import { CollectCriteria } from "./CollectCriteria.js";
import { CollectCriteriaDemographicsProps } from "./CollectCriteriaDemographics.js";
import { CollectCriteriaNhsNumberProps } from "./CollectCriteriaNhsNumber.js";
import { Footer } from "./Footer.js";
import { Header } from "./Header.js";
import { SearchReasoningDialog } from "./SearchReasoningDialog.js";
import { SearchResults } from "./SearchResults.js";

export type PatientSearchProps = Record<string, never>;

export const PatientSearch = ({}: PatientSearchProps) => {
  const dialogs = useDialogs();
  const navigate = useNavigate();

  const canSearchByNhsNumber = useSearchByNhsNumberEnabled();
  const mustRecordSearchReason = useRecordSearchReasonEnabled();

  // This is where we store the search result from the API
  const [searchResults, setSearchResults] = useState<
    undefined | "loading" | PatientIndexSearchResult
  >(undefined);

  // This is where we store the index of the selected search
  const [searchResultIndexSelected, setSearchResultIndexSelected] = useState<
    number | null
  >(null);

  // This is the selected patient from the search results
  const selectedPatient =
    searchResultIndexSelected === null || searchResults === "loading"
      ? undefined
      : searchResults?.matchedPatients?.[searchResultIndexSelected];

  // Calculate the overall state of the page
  const state =
    searchResults === undefined
      ? "search"
      : searchResults === "loading"
        ? "searching"
        : searchResults.matchedPatients.length === 0
          ? "results-not-found"
          : searchResultIndexSelected === null
            ? "results-found"
            : "result-selected";

  // Search for patients using NHS Number
  const searchByNhsNumber: CollectCriteriaNhsNumberProps["onSubmit"] = (
    values,
  ) => {
    // TODO: Use API
    console.log("Search by NHS number", values);
  };

  // Search for patients using Demographics
  const searchByDemographics: CollectCriteriaDemographicsProps["onSubmit"] =
    async (values) => {
      setSearchResults("loading");

      // TODO: How does this describe failure?
      const { data } = await api.patients.search(
        {
          type: "demographics",
          query: {
            given: values.givenName ?? undefined,
            family: values.familyName!,
            birthdate: values.dateOfBirthIsExact
              ? values.dateOfBirthExact
              : ageRangeToDateRange(values.ageApprox),
          },
        },
        "local",
      );

      setSearchResults(data);
    };

  // Redirect to the create patient page
  const onCreate = async () => {
    // TODO
    console.log("Create");
  };

  // Navigate to the patient page
  const onContinue = async () => {
    if (selectedPatient === undefined) {
      return;
    }

    if (mustRecordSearchReason) {
      const response = await dialogs.open(SearchReasoningDialog);
      if (response.type === "cancelled") {
        return;
      }

      // Record the search reason
      const _result = await api.patients.addSearchReasoning({
        reason: response.reasoning.reason,
        reasonDescription:
          response.reasoning.reason === "other"
            ? response.reasoning.description
            : undefined,
        selectedPatientId: selectedPatient?.id,
      });
    }

    // Redirect to the patient page
    navigate(routeFns.patientHome(selectedPatient.id));
  };

  return (
    <Stack>
      <Header />

      <CollectCriteria
        canSearchByNhsNumber={canSearchByNhsNumber}
        onSubmitNhsNumber={searchByNhsNumber}
        onSubmitDemographics={searchByDemographics}
      >
        {({ onClear }) => {
          return (
            <>
              <SearchResults
                searchResult={searchResults}
                onSelectedIndexChanged={setSearchResultIndexSelected}
              />

              <Footer
                state={state}
                onContinue={onContinue}
                onClear={() => {
                  // Clear the Formik fields
                  onClear();

                  // Clear the previous search results
                  setSearchResults(undefined);
                  setSearchResultIndexSelected(null);
                }}
                onCreate={onCreate}
              />
            </>
          );
        }}
      </CollectCriteria>
    </Stack>
  );
};

const ageRangeToDateRange = (ages: string | null) => {
  const parsedAges = ages === null ? null : JSON.parse(ages);
  if (
    parsedAges === null ||
    (parsedAges.min === undefined && parsedAges.max === undefined)
  ) {
    return null;
  }

  const startDate = dayjs()
    .subtract(parsedAges.max ?? 120, "years")
    .format("YYYY-MM-DD");
  const endDate = dayjs()
    .subtract(parsedAges.min ?? 0, "years")
    .format("YYYY-MM-DD");

  return [startDate, endDate];
};

const useSearchByNhsNumberEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration?.nhsNumberEnabled ?? true // TODO: What is the correct default?
  );
};

const useRecordSearchReasonEnabled = () => {
  const userContext = useContext(LoggedInUserContext);

  return (
    userContext?.user.sessionOrganisationConfiguration
      ?.patientSearchRecordReasonsEnabled ?? true // TODO: What is the correct default?
  );
};
