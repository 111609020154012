import React from "react";
import { useTranslation } from "react-i18next";
import { FormTitle } from "~/components/design-system/index.js";

export const Header = () => {
  const { t } = useTranslation();

  return (
    <FormTitle
      useReducedTopPadding={true}
      hasTitleBottomMargin={false}
      titleText={t("pages.patientSearch.title")}
    />
  );
};
