import { ExtendedThalamosUser, FormContextData } from "@aspire/common";
import { Box, useTheme } from "@mui/material";
import React, { ReactElement, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  renderErrorToast,
} from "~/components/design-system/index.js";
import { userCanEditMhaStatus } from "~/pages/EditMhaStatus/EditMhaStatusPage.js";
import { api } from "../../../api.js";
import { routeFns } from "../../../routes.js";
import { standaloneFormContextLogic } from "../helpers/formContextLogic.js";
import { Section4Banners } from "../helpers/Section4Banners.js";

export function StandaloneFormButtonActions({
  formContext,
  user,
  showFinaliseConfirmation,
  showMhaStatusConfirmation,
}: {
  formContext: FormContextData;
  user: ExtendedThalamosUser;
  showFinaliseConfirmation: (fullFormName: string) => void;
  showMhaStatusConfirmation: (fullFormName: string) => void;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  let buttons: ReactElement[] = [];
  const formDraftId = useMemo(v4, []);

  const {
    fullFormName,
    draftIdOnNextPart,
    canFinaliseForm,
    canLaunchDraft,
    canContinueForm,
    form,
    latestVersion,
    signedPartOneRecordOfDetention,
  } = standaloneFormContextLogic(formContext, user);

  if (!!signedPartOneRecordOfDetention) {
    // Section 4 banners
    buttons.push(<Section4Banners formContext={formContext} />);
  } else if (canContinueForm) {
    // Continue form banner
    buttons.push(
      <Banner
        title={`Continue ${
          latestVersion.data.length > 0
            ? `part ${latestVersion.data.length + 1} of `
            : ""
        }${fullFormName}`}
        rightButtonLabel={"Continue"}
        onRightButton={async () => {
          navigate(
            routeFns.formDraftsComplete(
              draftIdOnNextPart!,
              formContext.patientId,
            ),
          );
        }}
        bannerType={BannerList.INFO}
      />,
    );
  } else if (canLaunchDraft) {
    // Start form banner
    buttons.push(
      <Banner
        title={`Start ${
          latestVersion.data.length > 0
            ? `part ${latestVersion.data.length + 1} of `
            : ""
        }${fullFormName}`}
        rightButtonLabel={"Start"}
        rightButtonTestId="start-next-part-form"
        onRightButton={async () => {
          const response = await api.drafts.create(formDraftId, {
            formId: form.id,
          });
          if (response.status === 201) {
            navigate(
              routeFns.formDraftsComplete(formDraftId, formContext.patientId),
            );
          } else {
            renderErrorToast({ message: "Failed to create draft." });
          }
        }}
        bannerType={BannerList.INFO}
      />,
    );
  }

  const showEditMhaStatus = userCanEditMhaStatus(user);

  // Adminstrative scrunity banner
  if (canFinaliseForm) {
    buttons.push(
      <Banner
        title={"Mark administrative scrutiny for this assessment complete"}
        rightButtonLabel={"Complete"}
        rightButtonTestId="mark-scrutiny-complete-button"
        onRightButton={() =>
          showEditMhaStatus
            ? showMhaStatusConfirmation(fullFormName ?? "")
            : showFinaliseConfirmation(fullFormName ?? "")
        }
        bannerType={BannerList.INFO}
      />,
    );
  }

  return <Box sx={{ mb: theme.spacing(4) }}>{[buttons]}</Box>;
}
