import {
  BasePatientDemographics,
  FormPartTemplate,
  formUploadBaseTemplates,
  getFullFormName,
  Patient,
  SupportingFormsDetails,
} from "@aspire/common";
import { css } from "@emotion/react";
import {
  Close,
  InsertDriveFile,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { md5 } from "js-md5";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { base64 } from "rfc4648";
import { v4 } from "uuid";
import { object } from "yup";
import { MultiPagePdf } from "~/components/MultiPagePdf.js";
import {
  Banner,
  BannerList,
  FormTitle,
  HelperText,
  LoadingSpinner,
  PopupDialog,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system/index.js";
import {
  FormFooterSection,
  Upload,
  UploadedFile,
} from "~/components/form/index.js";
import { PatientBanner } from "~/components/layout/index.js";
import { Container, SubContainer } from "~/components/layout/styleWrappers.js";
import { LoggedInUserContext } from "../../Contexts.js";
import { api, apiHooks } from "../../api.js";
import { useScreenDetection } from "../../hooks/ScreenDetection/useScreenDetection.js";
import { usePatientTimeline } from "../../hooks/apiCalls.js";
import { routeFns } from "../../routes.js";
import { FieldProps } from "../FieldProps.js";
import { renderField } from "../FormDraft/FormDraftCompletionPage.js";
import { useScrollFirstErrorIntoViewEffect } from "../helpers/UseScrollFirstErrorIntoViewEffect/UseScrollFirstErrorIntoViewEffect.js";
import { FormUploadConfirmationModal } from "./FormUploadConfirmationModal.js";

import "react-pdf/dist/Page/TextLayer.css";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";

const FileViewerPopup = ({
  file,
  closeFn,
}: {
  file: UploadedFile | null;
  closeFn: (params: any) => void;
}) => {
  const { t } = useTranslation();
  const { isMobileView } = useScreenDetection();

  return (
    <PopupDialog open={true} onClose={closeFn} fullScreen={isMobileView}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          sx={{
            width: "40px",
            height: "40px",
          }}
          aria-label={t("buttonLabels.close")}
          onClick={closeFn}
        >
          <Close sx={{ color: "primary.hint" }} fontSize="small" />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" sx={{ padding: "1em" }}>
        {file?.type.startsWith("image/") ? (
          <img src={file.base64 as string} alt={`Upload`} height={500} />
        ) : (
          <MultiPagePdf data={(file?.base64 as string).split(",")[1]} />
        )}
      </Box>
    </PopupDialog>
  );
};

const UploadedFilesDisplay = React.memo(
  ({ uploadedFiles }: { uploadedFiles: UploadedFile[] | null }) => {
    return (
      <>
        {!!uploadedFiles?.length &&
          uploadedFiles.map((file, index) => (
            <div key={index}>
              <p>{index + 1}.</p>
              {file.type.startsWith("image/") ? (
                <img
                  src={file.base64 as string}
                  alt={`Upload ${index + 1}`}
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                <MultiPagePdf
                  maxWidth={500}
                  data={(file.base64 as string).split(",")[1]}
                />
              )}
            </div>
          ))}
      </>
    );
  },
);

type userDetails = {
  name: string | undefined;
  email: string | undefined;
  address: string | undefined;
  postalCode: string | undefined;
  isGuest: boolean;
  isConfirmed: boolean;
};

export function FormUploadInner({
  formContextId,
  patient,
  patientId,
  userDetails,
}: {
  formContextId: string;
  patient: BasePatientDemographics;
  patientId: string;
  userDetails: userDetails;
}) {
  const navigate = useNavigate();
  const [openFileViewerPopup, setOpenFileViewerPopup] = useState(false);
  const [currentFileViewer, setCurrentFileViewer] =
    useState<UploadedFile | null>(null);
  const { formTemplateIdFromUrl } = useParams();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[] | null>(
    null,
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [fileError, setFileError] = useState<string | null>(null);
  const { t } = useTranslation();

  const { isMobileView } = useScreenDetection();
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [checkboxConfirmedError, setCheckboxConfirmedError] =
    useState<string>("");

  const theme = useTheme();

  const { supportingFormsType: supportingFormsTypeParam } = useParams();

  const params = new URLSearchParams(supportingFormsTypeParam);

  const formIds = params.get("formIds")?.split(",");
  const reason = params.get("reason");

  let supportingFormsDetails: SupportingFormsDetails | undefined = undefined;

  if (!!formIds?.length) {
    supportingFormsDetails = {
      type: "provided",
      formIds: formIds!,
    };
  } else if (!!reason) {
    supportingFormsDetails = {
      type: "not-provided",
      reason: reason,
    };
  }

  let formTemplate =
    formUploadBaseTemplates.find((t) => t.id === formTemplateIdFromUrl) ??
    (formUploadBaseTemplates.length === 1
      ? formUploadBaseTemplates[0]
      : undefined);

  const partTemplates = formTemplate?.uploadablePartTemplates;

  const formId = useMemo(v4, []);

  const fullFormName = getFullFormName(formTemplate!);

  const overallSchema = object({
    part0: partTemplates?.[0]?.dataSchema!,
    part1: partTemplates?.[1]?.dataSchema!,
  });

  const patientDetails = {
    name: `${patient.name.given} ${patient.name.family}`,
    address: patient.address.address,
    postalCode: patient.address.postalCode,
  };

  const initialValues = {
    part0:
      partTemplates && partTemplates[0]
        ? {
            ...partTemplates[0].dataSchema.getDefault(),
            user: userDetails,
            patient: patientDetails,
          }
        : {},
    part1:
      partTemplates && partTemplates[1]
        ? {
            ...partTemplates[1].dataSchema.getDefault(),
            user: userDetails,
            patient: patientDetails,
          }
        : {},
  };

  const allFilesAreImages = uploadedFiles?.every((file) =>
    file.type.startsWith("image"),
  );
  const allFilesArePDFs = uploadedFiles?.every(
    (file) => file.type === "application/pdf",
  );

  const allFilesUploaded = uploadedFiles?.every((file) => file.uploadedKey);

  const mixedFileTypesDetected =
    !!uploadedFiles &&
    !!uploadedFiles.length &&
    !allFilesArePDFs &&
    !allFilesAreImages;

  const enableUploadFormSubmit =
    !!uploadedFiles && uploadedFiles.length > 0 && !!uploadedFiles[0].base64;

  const atLeastOneFileUploaded = uploadedFiles && uploadedFiles?.length >= 1;

  const onePdfAlreadySelected = allFilesArePDFs && atLeastOneFileUploaded;
  const oneImageAlreadyUploaded = allFilesAreImages && atLeastOneFileUploaded;

  const imageMimeTypes = ["image/gif", "image/jpeg", "image/png", "image/webp"];

  // Once an image has been uploaded, no longer allow PDFs to be uploaded
  const validMimeTypes = oneImageAlreadyUploaded
    ? imageMimeTypes
    : ["application/pdf", ...imageMimeTypes];

  const formik = useFormik({
    initialValues,
    validationSchema: overallSchema,
    onSubmit: async () => {
      if (!checkboxChecked)
        return setCheckboxConfirmedError(t("pages.formUpload.confirmLabel"));
      setShowConfirmModal(true);
    },
  });
  // Auto-scroll to the first form error on submit
  useScrollFirstErrorIntoViewEffect([formik.submitCount, formik.isSubmitting]);

  // Make a callback and store the latest version in a ref.
  // Only access this through the ref, not through the callback.
  // Without this, we end up losing formik information as the
  // formik.values get cached in the closure and become stale.
  const setMiniFormValues = useCallback(
    (index: number, vals: object) => {
      const newValues = {
        ...formik.values,
        ...(index === 0 ? { part0: vals } : { part1: vals }),
      };

      formik.setValues(newValues);
    },
    [formik],
  );
  const setMiniFormValuesRef = useRef(setMiniFormValues);
  setMiniFormValuesRef.current = setMiniFormValues;

  const TWO_MB_BYTES = 2 * 1024 * 1024;

  const generatePresignedUploadUrl = async (file: UploadedFile) => {
    const base64Data = file.transcodedImage?.base64
      ? file.transcodedImage!.base64
      : (file.base64 as string);

    const hash = md5.base64(base64.parse(base64Data.split(",")[1]));
    const response = await api.system.generatePresignedUploadUrl({
      fileName: file.name,
      contentMd5: hash,
    });
    if (!response.status || response.status !== 200) {
      throw new Error("Failed to generate presigned upload URL");
    }

    return { base64Data, hash, ...response.data };
  };

  const uploadFileToPresignedUploadUrl = async ({
    base64Data,
    hash,
    uploadUrl,
    fileType,
  }: {
    base64Data: string;
    hash: string;
    uploadUrl: string;
    fileType: string;
  }) => {
    const response = await axios.request({
      method: "PUT",
      url: uploadUrl,
      data: base64.parse(base64Data.split(",")[1]),
      headers: {
        "Content-Type": fileType,
        "Content-MD5": hash,
      },
      validateStatus: () => true,
    });

    if (!response.status || response.status !== 200) {
      throw new Error("Failed to upload file");
    }
  };

  const uploadFile = async (file: UploadedFile) => {
    const { base64Data, hash, uploadUrl, key } =
      await generatePresignedUploadUrl(file);
    await uploadFileToPresignedUploadUrl({
      base64Data,
      hash,
      uploadUrl,
      fileType: file.type,
    });
    setUploadedFiles((prevFiles) =>
      (prevFiles || []).map((f) =>
        f === file
          ? {
              ...file,
              uploadedKey: key,
            }
          : f,
      ),
    );
    return;
  };

  const processFile = (file: UploadedFile) => {
    if (file.inProgressUploadPromise === undefined) {
      file.inProgressUploadPromise = uploadFile(file);

      file.inProgressUploadPromise.catch((e) => {
        setUploadedFiles((prevFiles) =>
          (prevFiles || []).map((f) =>
            f === file
              ? {
                  ...file,
                  error: true,
                }
              : f,
          ),
        );
        renderErrorToast({ message: `Failed to upload file ${file.name}` });
      });
    }
  };

  const processFiles = async (files: UploadedFile[]) => {
    files.forEach(processFile);
    setUploadedFiles(files);
  };

  return (
    <>
      {showConfirmModal && (
        <FormUploadConfirmationModal
          formId={formId}
          formContextId={formContextId}
          formTemplate={formTemplate!}
          uploadedFiles={uploadedFiles!}
          formikValues={formik.values}
          supportingFormsDetails={supportingFormsDetails}
          onSuccessFn={() => {
            setShowConfirmModal(false);
            const message = t("pages.formUpload.successMessage");
            renderSuccessToast({ message });
            navigate(
              routeFns.formContextPageSuccessDialog(formContextId, patientId, {
                title: t("pages.formUpload.successMessageTitle"),
                message: message,
              }),
              { replace: true },
            );
          }}
          onClose={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      <Container>
        {openFileViewerPopup && (
          <FileViewerPopup
            file={currentFileViewer}
            closeFn={() => setOpenFileViewerPopup(false)}
          />
        )}
        <Box sx={{ mb: 3 }}>
          <FormTitle
            hasContainerMarginBottom={false}
            hasTitleBottomMargin={false}
            useReducedTopPadding={true}
            titleText={fullFormName}
          />
        </Box>

        <Banner
          title={t("pages.formUpload.warningBannerMessage")}
          bannerType={BannerList.INFO}
        />

        <Box sx={{ mt: 5, mb: 2 }}>
          <SubContainer>
            <Upload
              disabled={onePdfAlreadySelected ?? false}
              icon={<InsertDriveFile />}
              setUploadedFile={(callback) => {
                const files = callback(uploadedFiles);
                processFiles(files);
              }}
              transcodeImage={{
                // Keep width and height the same to ensure
                // parity between portrait and landscape images.
                maxWidth: 1500,
                maxHeight: 1500,
                // image/png is preferred as it is lossless and
                // deals with text better than jpeg.
                mimeType: "image/png",
              }}
              maxFileSizeBytes={TWO_MB_BYTES}
              fileTooLargeErrorText={t(
                "pages.common.signature.uploadLabelErrorFileSize2MB",
              )}
              setFileError={setFileError}
              labelType="form-upload"
              // Allow multiple files to be selected but must be uploaded one at a time
              multi={true}
              uploadMultiple={false}
              // We cannot limit the user to only PDFs and images because on Android if you
              // have an 'accept' set of mimetypes specifies it ignores capture='environment'
              // and shows a file picker - we only want the user to take photos on mobile
              // ASP-1777
              accept={
                !atLeastOneFileUploaded ? undefined : validMimeTypes.join(",")
              }
              validMimeTypes={validMimeTypes}
            />
            {mixedFileTypesDetected && (
              <HelperText
                errorMessage={t(
                  "pages.formUpload.mixedFileDetectedErrorMessage",
                )}
              />
            )}

            <HelperText subtext={t("pages.formUpload.uploadLabelSubtext")} />
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {!!uploadedFiles?.length &&
                uploadedFiles.map((f, index) =>
                  f.uploadedKey || f.error ? (
                    <Box display={"flex"} sx={{ mt: 2 }} key={index}>
                      <Chip
                        icon={
                          <IconButton
                            onClick={() => {
                              setCurrentFileViewer(f);
                              return setOpenFileViewerPopup(true);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        }
                        label={`${index + 1}. ${f.name}`}
                        onDelete={() =>
                          setUploadedFiles((prevFiles) =>
                            (prevFiles || []).filter((_, i) => i !== index),
                          )
                        }
                        deleteIcon={<Close />}
                        sx={[
                          { mr: 1 },
                          f.error ? { backgroundColor: "#FEF6F4" } : {},
                        ]}
                        aria-label={
                          f.error
                            ? `Failed to upload file ${f.name}`
                            : `${index + 1}. ${f.name}`
                        }
                      />
                    </Box>
                  ) : (
                    <Box display={"flex"} sx={{ mt: 2 }} key={index}>
                      <LoadingSpinner
                        label={t("pages.formUpload.uploadingLabel")}
                      />
                    </Box>
                  ),
                )}
            </Box>
          </SubContainer>

          {fileError && <Typography color="error">{fileError}</Typography>}
        </Box>
        {partTemplates && !!partTemplates.length && !!uploadedFiles?.length && (
          <Box display="flex" gap={5}>
            <SubContainer>
              <Box sx={{ mb: 3 }}>
                <FormTitle
                  hasContainerMarginBottom={false}
                  hasTitleBottomMargin={false}
                  useReducedTopPadding={true}
                  titleText={t("pages.formUpload.formDetailsLabel")}
                />
              </Box>
              {partTemplates.map((partTemplate, index) => {
                const values =
                  index === 0 ? formik.values.part0 : formik.values.part1;

                return (
                  <>
                    <Box sx={{ mb: 3 }}>
                      {index === 0 && partTemplates.length !== 1 && (
                        <FormTitle
                          hasContainerMarginBottom={false}
                          hasTitleBottomMargin={false}
                          useReducedTopPadding={true}
                          titleText={t(
                            "pages.formUpload.firstPractitionerTitle",
                          )}
                        />
                      )}
                      {index === 1 && (
                        <FormTitle
                          hasContainerMarginBottom={false}
                          hasTitleBottomMargin={false}
                          useReducedTopPadding={true}
                          titleText={t(
                            "pages.formUpload.secondPractitionerTitle",
                          )}
                        />
                      )}
                    </Box>
                    <MiniThalamosForm
                      partTemplate={partTemplate}
                      fieldProps={{
                        context: {},
                        validationSchema:
                          index === 0
                            ? partTemplates?.[0]?.dataSchema!
                            : partTemplates?.[1]?.dataSchema,
                        values,
                        setValues: (vals) => {
                          // We use the callback which is stored in a ref to ensure
                          // that the captured state is the latest available.
                          // If you just called setMiniFormValues(index, vals)
                          // then you would find you are losing formik data.
                          setMiniFormValuesRef.current(index, vals);
                        },

                        errors:
                          index === 0
                            ? formik.errors.part0 || ({} as any)
                            : formik.errors.part1 || ({} as any),
                        touched:
                          index === 0
                            ? formik.touched.part0 || ({} as any)
                            : formik.touched.part1 || ({} as any),
                        handleBlur: formik.handleBlur,
                        setFieldTouched: (
                          field: string,
                          isTouched?: boolean,
                          shouldValidate?: boolean,
                        ) => {
                          if (index === 0) {
                            formik.setFieldTouched(
                              `part0.${field}`,
                              isTouched,
                              shouldValidate,
                            );
                          } else {
                            formik.setFieldTouched(
                              `part1.${field}`,
                              isTouched,
                              shouldValidate,
                            );
                          }
                        },
                      }}
                    />
                  </>
                );
              })}
              <Typography
                component="div"
                css={css`
                  margin-bottom: ${theme.spacing(0.5)};
                  font-weight: bold;
                  font-size: ${theme.spacing(1.875)};
                `}
              >
                {t("pages.formUpload.certifyThatCheck")}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxChecked}
                    sx={{ color: "primary.main" }}
                    onChange={(e) => setCheckboxChecked(e.target.checked)}
                    id="confirmation-checkbox"
                    color="primary"
                    inputProps={{
                      "aria-label": t("pages.formUpload.confirmLabelAriaLabel"),
                    }}
                  />
                }
                label={
                  <Typography style={{ fontSize: theme.spacing(1.75) }}>
                    {t("pages.formUpload.confirmLabel")}
                  </Typography>
                }
              />
              {checkboxConfirmedError && !checkboxChecked && (
                <HelperText errorMessage={checkboxConfirmedError} />
              )}
            </SubContainer>
            {!isMobileView && (
              <SubContainer>
                <FormTitle
                  hasContainerMarginBottom={false}
                  hasTitleBottomMargin={false}
                  useReducedTopPadding={true}
                  titleText={t("pages.formUpload.previewLabel")}
                />

                <UploadedFilesDisplay uploadedFiles={uploadedFiles} />
              </SubContainer>
            )}
          </Box>
        )}
      </Container>
      <FormFooterSection
        saveLabel={t("buttonLabels.uploadForm")}
        discardLabel={t("buttonLabels.goBack")}
        isSticky
        isForcedSticky
        disableSubmit={
          !enableUploadFormSubmit || mixedFileTypesDetected || !allFilesUploaded
        }
        onSave={formik.handleSubmit}
        onCancel={() => {
          navigate(routeFns.formContextPage(formContextId, patientId));
        }}
      />
    </>
  );
}

function MiniThalamosForm({
  fieldProps,
  partTemplate,
}: {
  partTemplate: FormPartTemplate<any>;
  fieldProps: FieldProps<any>;
}) {
  return (
    <>
      {partTemplate &&
        partTemplate?.formBuilderFields?.map((field, index) => (
          <React.Fragment key={`${field.type}-${index}`}>
            {renderField(field, fieldProps)}
          </React.Fragment>
        ))}
    </>
  );
}

function WithPatientTimeline({
  patient,
  patientId,
}: {
  patient: Patient;
  patientId: string;
}) {
  const { patientTimeline, reloadPatientTimeline } = usePatientTimeline({
    patientId: patientId,
  });

  const nhsNumber = useGetNhsNumber({ patientId: patientId });

  return (
    <PatientBanner
      patient={patient}
      nhsNumber={nhsNumber ?? undefined}
      patientTimeline={patientTimeline}
      reloadPatientTimeline={reloadPatientTimeline}
    />
  );
}

export default function FormUpload() {
  const { formContextId } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(LoggedInUserContext);
  const user = userContext?.user;
  const userDetails = {
    name: user?.name,
    email: user?.email,
    isGuest: false,
    address: user?.address.address,
    postalCode: user?.address.postalCode,
    isConfirmed: true,
  };
  const [{ data: form, loading: formLoading }, reloadFormContext] =
    apiHooks.forms.getFormContext(formContextId!);

  useEffect(() => {
    if (!formLoading) {
      if (!form?.id) {
        navigate(routeFns.notFound(), { replace: true });
      } else if (form.activeTeamworkWorkItem?.status === "pending") {
        navigate(routeFns.formReview(form.id, form.patientId));
      }
    }
  }, [form, formLoading]);

  return form && !formLoading ? (
    <>
      <WithPatientTimeline
        patientId={form.patientId}
        patient={form.patient}
      ></WithPatientTimeline>
      <FormUploadInner
        formContextId={form.id}
        patient={{ name: form.patient.name, address: form.patient.address }}
        patientId={form.patientId}
        userDetails={userDetails}
      />
    </>
  ) : null;
}
