import { baseTemplates, doesUserMeetRequirement } from "@aspire/common";
import {
  Box,
  Breakpoint,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeCssVarOverrides,
  Typography,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { v4 } from "uuid";
import {
  Banner,
  BannerList,
  Button,
  PopupDialog,
  PopupDialogTitle,
  renderSuccessToast,
  Typeahead,
} from "~/components/design-system/index.js";
import { api } from "../api.js";
import { LoggedInUserContext } from "../Contexts.js";
import { routeFns } from "../routes.js";

import {
  BaseFormTemplate,
  Form,
  h3V1Base,
  isGuestUserSession,
  ValidationOutcome,
} from "@aspire/common";
import { AxiosResponse } from "axios";
import {
  FormAssigneeData,
  FormAssigneeDialog,
} from "../components/FormAssignee/FormAssigneeComponent.js";
import { BannerDialog } from "./FormProgressPage/helpers/BannerDialog.js";
import { AdmissionWarningDialog } from "./PatientHomePage.js";
import { PatientTimelineCard } from "./PatientTimelineCard.js";

import {
  CreateExternalPatientDemograhicsPullEventRequest,
  CreateForm,
  findFormsMerged,
  FormContextData,
  initialiseForm,
  nonThalamosFormTemplateIds,
  PatientTimelineResponse,
  preprocessTemplates,
  SelectedFormsObject,
} from "@aspire/common";
import { FormSupportingDocumentsSelector } from "./FormDraft/FormSupportingDocumentsSelector.js";
import {
  useClearSupportingDocuments,
  useFormTemplateUpdater,
} from "./helpers/FormTemplates/FormTemplates.js";

import { canLaunchForm } from "@aspire/common";

export type DialogType = "record-of-detention" | undefined;

type NewFormDialogMode = "start" | "request";

type FormTemplateWithLongDescription = BaseFormTemplate & {
  longDescription: string;
};

export function NewFormDialog({
  patientId,
  closeDialog,
  title,
  allowRequestForm,
  existingFormContext,
  formTemplateFilterFn,
  linkedForms: externalLinkedForms,
  allowGuestToLaunchAnyForm,
  existingAdmissions,
  dialogType,
  existingWorkItems,
  maxWidth,
  patientTimeline,
  syncExternalPatientLink,
}: {
  patientId: string;
  closeDialog: () => void;
  title?: string;
  allowRequestForm: boolean;
  existingFormContext?: FormContextData;
  formTemplateFilterFn: (template: BaseFormTemplate) => boolean;
  linkedForms?: Form<unknown>["linkedForms"];
  allowGuestToLaunchAnyForm?: boolean;
  dialogType?: DialogType;
  existingAdmissions?: Omit<FormContextData, "patient">[];
  existingWorkItems?: Omit<FormContextData, "patient">[];
  maxWidth?: Breakpoint | false;
  patientTimeline?: PatientTimelineResponse;
  syncExternalPatientLink?: (
    reason: CreateExternalPatientDemograhicsPullEventRequest["reason"],
  ) => Promise<void>;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { category, id: formTemplateIdFromUrl } = useParams();

  // Retrieve details from the user context
  const user = useContext(LoggedInUserContext)!.user;
  const isTrainingContext = user.sessionContext?.isTrainingOrganisation;
  const inTeamContext = user.sessionContext?.type === "team";

  const isAcdEnabled = user.sessionOrganisationConfiguration?.acdEnabled;
  const enabledForms = user.sessionOrganisationConfiguration?.enabledForms;

  // Setup form submission and dialog visibility state
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);
  const [showAdmissionWarningDialog, setShowAdmissionWarningDialog] =
    useState(false);
  const [inviteSuccessMessageName, setInviteSuccessMessageName] = useState();
  const [isOpenBannerDialog, setIsOpenBannerDialog] = useState(false);
  const [isOpenNewFormDialog, setIsOpenNewFormDialog] = useState(true);

  const hasDoctorWithGmcNumber = user.accreditations.some(
    (accreditation) =>
      accreditation.type === "doctor" && accreditation.gmcNumber,
  );

  // Get all form templates
  const allTemplates = preprocessTemplates(baseTemplates);

  // Form templates where the currently logged-in user can sign the first part of
  // the form, either by virtue of their professional accreditations or their team memberships
  const templateUserMayStartOrInvite = allTemplates.filter((t) => {
    const isCategoryMatch = !category || t.category === category;
    const isAcdMatch = isAcdEnabled || t.id !== "acd";
    const isGmcNumberMatch = !(hasDoctorWithGmcNumber && t.id === "mha-h3"); // If a doctor has a GMC number then the h3 should not be displayed
    const isFormMatch =
      formTemplateFilterFn(t) &&
      (enabledForms?.some((f) => t.id === f.id && t.version === f.version) ||
        existingFormContext);
    const isThalamosTemplate = !nonThalamosFormTemplateIds.includes(t.id);

    return (
      isCategoryMatch &&
      isAcdMatch &&
      isGmcNumberMatch &&
      isFormMatch &&
      isThalamosTemplate
    );
  });

  const templateUserMayStart = templateUserMayStartOrInvite.filter((t) => {
    return (
      doesUserMeetRequirement(user, t.parts[0].signing.userOrTeamRequirement) ||
      (allowGuestToLaunchAnyForm && isGuestUserSession(user))
    );
  });

  // Compute the mode of the form
  const initialMode: NewFormDialogMode = !!templateUserMayStart.length
    ? "start"
    : "request";
  const [selectedMode, setSelectedMode] =
    useState<NewFormDialogMode>(initialMode);

  // Filter templates by mode (start vs. invite) and form category
  const activeTemplates =
    selectedMode === "start"
      ? templateUserMayStart
      : templateUserMayStartOrInvite;

  const { initialFormInputValue, initialFormTemplate } = initialiseForm(
    activeTemplates,
    formTemplateIdFromUrl,
  );

  const [formTemplate, setFormTemplate] = useState<
    FormTemplateWithLongDescription | undefined
  >(initialFormTemplate);

  const [formInputValue, setFormInputValue] = useState<string>(
    initialFormInputValue,
  );

  const formTemplateId = formTemplate?.id;
  const isH3Form = formTemplateId === h3V1Base.id;
  const signingRequirements = formTemplate?.parts?.[0].signing;
  const linkableForms = signingRequirements?.linkableForms;
  const requiresSupportingForms = !!existingFormContext && !!linkableForms;

  useFormTemplateUpdater(
    formTemplateIdFromUrl,
    formInputValue,
    activeTemplates,
    formTemplate,
    setFormTemplate,
  );

  const formId = useMemo(v4, []);
  const formDraftId = useMemo(v4, []);
  const assignmentId = useMemo(v4, []);

  // Supporting document (e.g. medical recommendations) selection state
  const [supportingFormsProvided, setSupportingFormsProvided] =
    useState<boolean>(false);
  const [selectedForms, setSelectedForms] = useState<SelectedFormsObject[]>([]);
  const [notSelectedReason, setNotSelectedReason] = useState<string>("");
  const [validationOutcome, setValidationOutcome] = useState<
    ValidationOutcome[] | null
  >(null);

  useClearSupportingDocuments(
    formTemplateId,
    setSupportingFormsProvided,
    setSelectedForms,
    setNotSelectedReason,
    setValidationOutcome,
  );

  const selectedLinkableForm = formTemplate?.linkableForms?.find((f) =>
    selectedForms.map((s) => s.templateId).includes(f.templateId),
  );

  const linkedForms =
    externalLinkedForms ||
    (requiresSupportingForms &&
      selectedForms.map(({ id }) => ({
        id,
        reason: selectedLinkableForm?.reason || "supported-by",
      }))) ||
    undefined;

  const matchingWorkItems =
    existingWorkItems?.filter((existingWorkItem) => {
      return existingWorkItem.forms.some((form) => {
        return form.formTemplate.id === formTemplate?.id;
      });
    }) || [];

  const canContinueOrStartForm = canLaunchForm({
    linkableForms,
    supportingFormsProvided,
    notSelectedReason,
    validationOutcome,
    requiresSupportingForms,
  });

  async function createForm(
    workItem: CreateForm & { id: string },
  ): Promise<AxiosResponse<unknown, unknown>> {
    return api.forms.create(formId, {
      patientId,
      formTemplate: { id: formTemplate!.id, version: formTemplate!.version },
      workItem: existingFormContext ? undefined : workItem,
      formContext: existingFormContext
        ? { mode: "existing", id: existingFormContext.id }
        : { mode: "new", type: "standalone", id: v4() },
      linkedForms,
    });
  }

  const launchNewFormFn = async () => {
    // Sync with EPR before starting a new form
    if (syncExternalPatientLink) {
      await syncExternalPatientLink("started form");
    }

    setSubmitError(undefined);
    const workItem = {
      id: assignmentId,
      assignedUserId: user.id,
      teamId: (inTeamContext ? user.sessionContext!.teamId : undefined)!,
    };

    const createAssignmentResult = await createForm(workItem);

    if (createAssignmentResult.status === 204) {
      const result = await api.drafts.create(formDraftId, {
        formId,
      });

      if (result.status === 201) {
        navigate(routeFns.formDraftsComplete(formDraftId, patientId), {
          replace: true,
        });
      }

      return;
    } else {
      // @ts-expect-error - data is "unknown"
      setSubmitError(createAssignmentResult.data?.reason || "Unknown error");
    }
  };

  const requestForm = async (values: FormAssigneeData) => {
    setSubmitError(undefined);
    setSubmitting(true);

    const workItem =
      values.type === "guest"
        ? { id: assignmentId, email: values.email, name: values.name }
        : {
            id: assignmentId,
            teamId: values.teamId!,
            assignedUserId: values.assignedUserId,
          };
    const result = await createForm(workItem);

    if (result.status === 204) {
      setIsOpenNewFormDialog(false);
      setIsOpenBannerDialog(true);
      renderSuccessToast({
        message: `Request successfully sent to ${inviteSuccessMessageName}`,
      });
    } else {
      setSubmitError(
        // @ts-expect-error - data is "unknown"
        result.data?.reason || "Unknown error",
      );
    }
    setSubmitting(false);
  };

  const saveFn = async () => {
    if (isH3Form && !!existingAdmissions?.length)
      return setShowAdmissionWarningDialog(true);
    if (!!matchingWorkItems.length) {
      return navigate(
        routeFns.patientFormLaunch(patientId!, category, formTemplate!.id),
      );
    }
    setSubmitting(true);
    await launchNewFormFn();
    setSubmitting(false);
  };

  const completeDialog = (primaryButton: JSX.Element) => (
    <>
      {submitError && (
        <Box sx={{ my: 2 }}>
          <Banner title={submitError} bannerType={BannerList.ERROR} />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          testId="close-modal-button"
          variant="outlined"
          label={t("pages.patientHome.launchForm.close")}
          onClick={closeDialog}
        />
        {primaryButton}
      </Box>
    </>
  );

  let content;

  if (showAdmissionWarningDialog) {
    content = (
      <AdmissionWarningDialog
        existingAdmissions={existingAdmissions!}
        patientId={patientId}
        launchNewFormFn={launchNewFormFn!}
        patientTimeline={patientTimeline}
        syncExternalPatientLink={syncExternalPatientLink}
      />
    );
  } else if (formTemplateIdFromUrl) {
    content = (
      <FormAlreadyInProgressDialog
        closeDialog={closeDialog}
        launchNewFormFn={launchNewFormFn}
        patientId={patientId}
        description={formTemplate?.longDescription ?? ""}
        matchingWorkItems={matchingWorkItems}
        patientTimeline={patientTimeline}
      />
    );
  } else {
    content = (
      <>
        <PopupDialogTitle titleText={title} closeDialog={closeDialog} />
        <Box>
          {/* If requesting a form is allowed and there ARE NO form types that the user is allowed 
            to create then show a message saying to "invite another user" */}
          {allowRequestForm && !templateUserMayStart.length && (
            <Box sx={{ mb: 4 }}>
              <Banner
                title={t("pages.patientHome.launchForm.requestFormCompletion")}
              />
            </Box>
          )}

          {/* If requesting a form is allowed and there ARE SOME form types that the user is allowed to create
              Then allow the user to pick between "starting the form" themselves or "inviting another user" */}
          {allowRequestForm && !!templateUserMayStart.length && (
            <>
              <FormModeSelection
                selectedMode={selectedMode}
                setSelectedMode={setSelectedMode}
                formTemplateId={formTemplateId}
              />
            </>
          )}

          {/* Form template input */}
          <Box sx={{ mb: 0.5 }}>
            <Typeahead
              testId="select-new-form"
              onInputChange={setFormInputValue}
              placeholder={t("components.typeahead.placeholder")}
              name={"select-form"}
              inputValue={formInputValue}
              label={t("components.typeahead.label")}
              options={activeTemplates.map((f) => ({
                value: f.id,
                label: f.longDescription,
              }))}
            />
          </Box>

          {/* If we are in "start" mode (or the user has not yet selected a form template) 
              Then just show the footer buttons */}
          {(selectedMode === "start" || !formTemplate) &&
            !formTemplateIdFromUrl && (
              <>
                {requiresSupportingForms ? (
                  <>
                    <FormSupportingDocumentsSelector
                      formContext={existingFormContext!}
                      linkableForms={linkableForms}
                      selectedForms={selectedForms}
                      setSelectedForms={setSelectedForms}
                      notSelectedReason={notSelectedReason}
                      setNotSelectedReason={setNotSelectedReason}
                      supportingFormsProvided={supportingFormsProvided}
                      dialogType={dialogType}
                      setSupportingFormsProvided={setSupportingFormsProvided}
                      setValidationOutcome={setValidationOutcome}
                    />
                    {completeDialog(
                      <Button
                        testId="start-form-button"
                        label={t("pages.patientHome.launchForm.startForm")}
                        disabled={!canContinueOrStartForm}
                        onClick={saveFn}
                      />,
                    )}
                  </>
                ) : (
                  <>
                    {completeDialog(
                      <Button
                        testId="start-form-button"
                        label={t("pages.patientHome.launchForm.startForm")}
                        disabled={
                          submitting ||
                          !formTemplate ||
                          selectedMode === "request"
                        }
                        onClick={saveFn}
                      />,
                    )}
                  </>
                )}
              </>
            )}

          {/* If we are in "requst" mode (and the user has selected a form template)
              Then show the "select assignee" control AND the footer buttons */}
          {selectedMode === "request" && formTemplate && (
            <>
              <FormAssigneeDialog
                formDetails={{
                  template: {
                    id: formTemplate.id,
                    version: formTemplate.version,
                  },
                  part: 1,
                }}
                user={user}
                setInviteSuccessMessageName={setInviteSuccessMessageName}
                onSave={requestForm}
                submissionComponentFn={(formikValues) => {
                  const {
                    type: userType,
                    isTrainingOrganisation,
                    name,
                    teamId,
                  } = formikValues.values;

                  const userNotSelected =
                    userType === "full-user" ? !teamId : !name;

                  const shareToNonTrainingUserFromTraining =
                    !userNotSelected &&
                    isTrainingContext &&
                    !isTrainingOrganisation &&
                    formikValues.values.type !== "guest";

                  const shareToTrainingUserFromNonTraining =
                    !userNotSelected &&
                    !isTrainingContext &&
                    isTrainingOrganisation;

                  let warningMessage = null;
                  if (shareToNonTrainingUserFromTraining)
                    warningMessage = t("training.onlyShareToTrainingUsers");
                  if (shareToTrainingUserFromNonTraining)
                    warningMessage = t("training.cannotShareToTrainingUsers");

                  return (
                    <>
                      {warningMessage && (
                        <Box sx={{ mb: 4 }}>
                          <Banner
                            title={warningMessage}
                            bannerType={BannerList.WARNING}
                          />
                        </Box>
                      )}
                      {completeDialog(
                        <Button
                          testId="invite-user-button"
                          label={t("pages.patientHome.launchForm.inviteUser")}
                          disabled={
                            submitting ||
                            userNotSelected ||
                            !!shareToNonTrainingUserFromTraining ||
                            !!shareToTrainingUserFromNonTraining
                          }
                          onClick={formikValues.submitForm}
                        />,
                      )}
                    </>
                  );
                }}
              />
            </>
          )}
        </Box>
      </>
    );
  }
  return (
    <>
      <PopupDialog
        open={isOpenNewFormDialog}
        fullWidth
        maxWidth={maxWidth}
        testId="new-form-modal"
      >
        {content}
      </PopupDialog>
      {isOpenBannerDialog && (
        <BannerDialog
          title={t("pages.patientHome.launchForm.inviteSuccessfullySentLabel")}
          message={`
            ${t(
              "pages.patientHome.launchForm.inviteSuccessfullySentMessage",
            )} ${inviteSuccessMessageName}`}
          onClose={() => {
            navigate(routeFns.patientHome(patientId!));
          }}
        />
      )}
    </>
  );
}

function FormAlreadyInProgressDialog({
  closeDialog,
  matchingWorkItems,
  description,
  launchNewFormFn,
  patientId,
  patientTimeline,
}: {
  closeDialog: () => void;
  matchingWorkItems: Omit<FormContextData, "patient">[];
  description: string;
  launchNewFormFn: () => ThemeCssVarOverrides;
  patientId: string;
  patientTimeline?: PatientTimelineResponse;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedRadioValue, setSelectedRadioValue] = useState(null);
  const [continueWarningMessage, setContinueWarningMessage] = useState("");

  return (
    <>
      <PopupDialogTitle
        titleText={t("pages.patientHome.launchForm.popupDialogTitle")}
        closeDialog={closeDialog}
      />
      <Box sx={{ mb: 4 }}>
        <Banner
          title={
            `${description} ` +
            t(`pages.patientHome.launchForm.formProgressWarningMessage`)
          }
          bannerType={BannerList.WARNING}
        />
        {!!matchingWorkItems.length && (
          <Box sx={{ mt: 4 }}>
            {continueWarningMessage && !selectedRadioValue && (
              <Typography sx={{ color: "red", mb: 1 }}>
                {continueWarningMessage}
              </Typography>
            )}
            {matchingWorkItems.map((matchingWorkItem) => {
              const merged = findFormsMerged(
                patientTimeline?.mergedPatientData,
                matchingWorkItem,
              );
              return (
                <PatientTimelineCard
                  formContext={matchingWorkItem!}
                  showRadioSelector={matchingWorkItems.length > 1}
                  selectedRadioValue={selectedRadioValue!}
                  setSelectedRadioValue={setSelectedRadioValue}
                  lastMerged={merged?.lastMerged}
                />
              );
            })}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          label={t("buttonLabels.startNew")}
          testId="start-new-button"
          onClick={() => launchNewFormFn()}
        />
        <Button
          label={t("buttonLabels.continue")}
          testId="continue-button"
          onClick={async () => {
            if (selectedRadioValue)
              return navigate(
                routeFns.formContextPage(selectedRadioValue, patientId),
              );

            if (matchingWorkItems.length === 1) {
              const id = matchingWorkItems[0].id;
              return navigate(routeFns.formContextPage(id, patientId));
            }
            setContinueWarningMessage(
              t("pages.patientHome.launchForm.radioSelectorWarningMessage"),
            );
          }}
        />
      </Box>
    </>
  );
}

function FormModeSelection({
  selectedMode,
  setSelectedMode,
  formTemplateId,
}: {
  selectedMode: string;
  setSelectedMode: (params: any) => void;
  formTemplateId: string | undefined;
}) {
  const { t } = useTranslation();
  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="form-mode-selection-label"
        value={selectedMode}
        onChange={(e) => setSelectedMode(e.target.value)}
        name="form-mode-selection"
      >
        <FormControlLabel
          value="start"
          control={<Radio />}
          label={t("pages.patientHome.launchForm.startNew")}
        />
        <FormControlLabel
          value="request"
          control={<Radio />}
          label={t("pages.patientHome.launchForm.requestFormCompletion")}
        />
      </RadioGroup>
      {selectedMode === "start" && formTemplateId === "mha-h3" && (
        <ValidityBanner />
      )}
    </FormControl>
  );
}

function ValidityBanner() {
  const { t } = useTranslation();
  return (
    <Banner
      title={t("pages.patientHome.launchForm.admissionValidityInstructions")}
      bannerType={BannerList.WARNING}
    />
  );
}
