import { TermsAndConditions, UUID } from "@aspire/common";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Markdown from "react-markdown";
import { apiHooks } from "~/api.js";
import {
  Button,
  LoadingSpinner,
  PopupDialog,
} from "../../design-system/index.js";

export type TermsAndConditionsModalInnerProps = {
  termsAndConditions: TermsAndConditions;
  onClose: () => void;
};

export function TermsAndConditionsModalInner({
  termsAndConditions,
  onClose,
}: TermsAndConditionsModalInnerProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);

  return (
    <PopupDialog
      title={t("pages.patientEditCreate.updateSuccess")}
      open={true}
      onClose={() => {}}
    >
      <Markdown>{termsAndConditions?.markdown}</Markdown>
      <FormControlLabel
        control={
          <Checkbox
            // @ts-ignore
            inputProps={{ "data-testid": "terms-and-conditions-checkbox" }}
            checked={checked}
            onClick={() => setChecked(!checked)}
          />
        }
        label={t("layout.termsAndConditionsModal.confirmationText")}
      />
      <Button
        testId="continue-terms-and-conditions"
        label={t("buttonLabels.continue")}
        onClick={() => {
          onClose();
        }}
        disabled={!checked}
      />
    </PopupDialog>
  );
}

export function TermsAndConditionsModal(props: {
  termsAndConditionsId: UUID;
  onClose: () => void;
}) {
  const [{ data: termsAndConditions, loading: loadingTermsAndConditions }] =
    apiHooks.termsAndConditions.get(props.termsAndConditionsId);

  return loadingTermsAndConditions ? (
    <PopupDialog title="Loading" open={true} onClose={() => {}}>
      <LoadingSpinner />
    </PopupDialog>
  ) : (
    <TermsAndConditionsModalInner
      termsAndConditions={termsAndConditions!}
      onClose={props.onClose}
    />
  );
}
