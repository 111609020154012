import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Banner,
  BannerList,
  Button,
  renderErrorToast,
  ReviewNewExternalPatientRecord,
  SectionTitle,
} from "~/components/design-system/index.js";
import { api } from "../../api.js";
import { DefaultPageProps } from "../defaultProps.js";

import {
  BasePatientDemographics,
  ExternalPatientLinkEnhanced,
} from "@aspire/common";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { useExternalPatientLink } from "~/hooks/ExternalPatientLink/useExternalPatientLink.js";
import { routeFns } from "~/routes.js";

// Holds the state for this page.
type State =
  | {
      // Something has gone wrong and needs presenting to the user.
      type: "error";
      error: string;
    }
  | {
      // The patient details were not found on the external system.
      type: "not-found";
    }
  | {
      // The patient already exists in eMHA, and we need to review their details.
      type: "externalPatientLink";
      externalPatientLink: ExternalPatientLinkEnhanced;
      rootPatientId: string;
    }
  | {
      // The patient already exists in eMHA, but they have been merged into another patient,
      // and this link is not the higest priority.
      type: "mergedExternalPatientLink";
      rootPatientId: string;
    }
  | {
      // The patient does not exist in eMHA, and we need to create them.
      type: "newPatient";
      demographics: BasePatientDemographics & {
        nhsNumber?: string;
      };
      externalSystemType: ExternalPatientLinkEnhanced["externalSystemType"];
      externalSystemId: ExternalPatientLinkEnhanced["externalSystemId"];
      externalPatientIdType: ExternalPatientLinkEnhanced["externalPatientIdType"];
      externalPatientIdValue: ExternalPatientLinkEnhanced["externalPatientIdValue"];
    };

export function PatientContextLaunchPage({}: DefaultPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Extract the query params
  const { search } = useLocation();
  const qs = new URLSearchParams(search);

  const token = qs.get("token");
  if (token === null) {
    throw new Error("Missing required query parameters");
  }

  const [state, setState] = useState<State | undefined>(undefined);

  // We are managing at most one external patient link
  const { Component: ExternalPatientLinkComponents, syncExternalPatientLink } =
    useExternalPatientLink(
      state?.type === "externalPatientLink"
        ? state.externalPatientLink
        : undefined,
    );

  // Look for an existing patient with this link
  useEffect(() => {
    const doEffect = async () => {
      // Redeem the token to get the external system details
      const redeemTokenResponse = await api.rio.clickThrough.redeemToken({
        token: token,
      });

      if (redeemTokenResponse.status !== 200) {
        setState({
          type: "error",
          error: "failed to redeem token",
        });
        return;
      }

      const {
        externalSystemId,
        externalSystemType,
        externalPatientIdType,
        externalPatientIdValue,
      } = redeemTokenResponse.data;

      // Try and find an existing link to a patient
      const searchExternalPatientLinksResponse =
        await api.patients.searchExternalPatientLinks({
          externalSystemType,
          externalSystemId,
          externalPatientIdType,
          externalPatientIdValue,
          rioToken: token,
        });

      // We already have a link.
      if (searchExternalPatientLinksResponse.status === 200) {
        const clickedThroughLink = searchExternalPatientLinksResponse.data;

        // Get the local patient record. If the patient has been merged, then
        // this will get the root patient at the end of the merge chain.
        const rootPatient = await api.patients.get(
          clickedThroughLink.patientId,
        );
        if (rootPatient.status !== 200 || rootPatient.data === null) {
          setState({
            type: "error",
            error: "failed to fetch root patient",
          });
          return;
        }

        // Get the external links for the root patient so we can check whether
        // the link we clicked through on is the highest priority for this patient.
        // If not, we're going to show them a warning.
        const patientLinks = await api.patients.getExternalPatientLinks(
          rootPatient.data.id,
        );
        if (patientLinks.status !== 200) {
          setState({
            type: "error",
            error: "failed to fetch external patient links",
          });
          return;
        }

        // Try and find the highest priority link for the root patient
        // on the same external system as the clickthrough link.
        const highestPriorityLink = patientLinks.data.externalLinks
          .filter(
            (link) =>
              link.externalSystemType ===
                clickedThroughLink.externalSystemType &&
              link.externalSystemId === clickedThroughLink.externalSystemId,
          )
          .sort(
            (a, b) =>
              a.priorityWithinExternalSystem - b.priorityWithinExternalSystem,
          )[0];

        const clickedThroughIsHighestPriority =
          highestPriorityLink?.id === clickedThroughLink.id;

        if (clickedThroughIsHighestPriority) {
          setState({
            type: "externalPatientLink",
            externalPatientLink: clickedThroughLink,
            rootPatientId: rootPatient.data.id,
          });
          return;
        } else {
          setState({
            type: "mergedExternalPatientLink",
            rootPatientId: rootPatient.data.id,
          });
          return;
        }
      } else {
        // We don't have a link, so we need to create a new patient

        // Get the patient details from the external system
        const res = await api.patients.createFromExternalId({
          externalSystemType,
          externalSystemId,
          externalPatientIdType,
          externalPatientIdValue,
        });

        if (res.status === 200 && "demographics" in res.data) {
          setState({
            type: "newPatient",
            demographics: res.data.demographics,
            externalSystemType,
            externalSystemId,
            externalPatientIdType,
            externalPatientIdValue,
          });
        } else if (res.status === 404) {
          setState({ type: "not-found" });
        } else {
          setState({
            type: "error",
            error:
              (res.data as any).reason ??
              "Failed to fetch patient details from external system",
          });
        }
      }
    };

    doEffect();
  }, [token]);

  // If we have an existing link, do a pull and review
  useEffect(() => {
    const doEffect = async () => {
      if (state?.type === "externalPatientLink") {
        // If we have permission to pull demographics from this link, then
        // attempt to do so.
        if (state.externalPatientLink.canPull) {
          // All of the interaction with the user is handled by this method.
          // When the interaction is complete, then the promise completes.
          await syncExternalPatientLink("click through");
        }

        // Navigate to the patient page
        navigate(`/patients/${state.rootPatientId}`, { replace: true });
      }
    };

    doEffect();
  }, [state, syncExternalPatientLink, navigate]);

  // If it is a new patient we will need to let them review the details.
  // We can't re-use the code within syncExternalPatientLink() because the
  // user may reject the patient details, which results in no eMHA patient
  // record or patient link being created.
  const patientRecords = useMemo(
    () =>
      state?.type === "newPatient"
        ? {
            Rio: state.demographics,
          }
        : undefined,
    [state],
  );

  const NHSNumber = useMemo(
    () =>
      state?.type === "newPatient" ? state.demographics.nhsNumber : undefined,
    [state],
  );

  // Create this id outside of the handler so that multiple clicks of
  // the button do not result in multiple links being created.
  const newExternalPatientLinkId = useMemo(v4, []);

  // This is called when the user confirms the review dialog
  const onConfirm = useCallback(async () => {
    if (state?.type !== "newPatient") {
      return false;
    }

    // Create the new patient
    const result = await api.patients.createFromExternalId({
      externalSystemType: state.externalSystemType,
      externalSystemId: state.externalSystemId,
      externalPatientIdType: state.externalPatientIdType,
      externalPatientIdValue: state.externalPatientIdValue,
      confirmCreate: true,
    });

    if (result.status !== 200 || !("existingPatient" in result.data)) {
      renderErrorToast({
        message: t(
          "components.externalPatientLink.reviewNewExternalPatientRecord.toast.failedToCreatePatient",
        ),
      });
      return false;
    }

    // Create an external patient link
    const externalLink = await api.patients.createExternalPatientLink(
      result.data.existingPatient.id,
      {
        id: newExternalPatientLinkId,
        externalSystemType: state.externalSystemType,
        externalSystemId: state.externalSystemId,
        externalPatientIdType: state.externalPatientIdType,
        externalPatientIdValue: state.externalPatientIdValue,
        priorityWithinExternalSystem: 1,
        rioToken: token,
      },
    );

    if (externalLink.status !== 200) {
      renderErrorToast({
        message: t(
          "components.externalPatientLink.reviewNewExternalPatientRecord.toast.failedToCreateLink",
        ),
      });
      return false;
    }

    // Create a pull demographics event.
    const pullEventId = v4();
    const pullEvent =
      await api.patients.createExternalPatientDemographicsPullEvent(
        result.data.existingPatient.id,
        {
          id: pullEventId,
          externalPatientLinkId: newExternalPatientLinkId,
          created: dayjs().toISOString(),
          reason: "click through",
        },
      );

    if (pullEvent.status !== 200) {
      // This error is not worth showing to the user.
      // The side effect is that the "Sync with Rio" button will
      // have a red dot on it.
      return false;
    }

    // Mark the pull demographics event as processed.
    await api.patients.updateExternalPatientDemographicsPullEvent(
      result.data.existingPatient.id,
      pullEventId,
      { processed: true },
    );

    // Navigate to the new patient record
    navigate(`/patients/${result.data.existingPatient.id}`, { replace: true });
    return true;
  }, [navigate, newExternalPatientLinkId, state, t, token]);

  // This is called when the user cancels the review dialog
  const onCancel = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  const titleText = t(
    state?.type === "mergedExternalPatientLink"
      ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.mergedExternalPatientLink"
      : state?.type === "externalPatientLink"
        ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.externalPatientLink"
        : state?.type === "newPatient"
          ? "components.externalPatientLink.reviewNewExternalPatientRecord.title.newPatient"
          : "components.externalPatientLink.reviewNewExternalPatientRecord.title.contacting",
  );

  const isLoading = state === undefined;

  return isLoading ? (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Stack gap="1rem">
      <ExternalPatientLinkComponents />
      <SectionTitle
        titleText={titleText}
        subtitleText={NHSNumber ? `NHS Number: ${NHSNumber}` : undefined}
      ></SectionTitle>
      {state?.type === "error" ? (
        <Banner bannerType={BannerList.ERROR} title={state.error} />
      ) : state?.type === "not-found" ? (
        <Banner
          bannerType={BannerList.ERROR}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.notFound",
          ).split("\n")}
        />
      ) : state?.type === "mergedExternalPatientLink" ? (
        <Banner
          bannerType={BannerList.WARNING}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.mergedExternalPatientLink",
          ).split("\n")}
        />
      ) : state?.type === "newPatient" ? (
        <Banner
          bannerType={BannerList.INFO}
          body={t(
            "components.externalPatientLink.reviewNewExternalPatientRecord.banner.newPatient",
          ).split("\n")}
        />
      ) : null}
      {patientRecords !== undefined && (
        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
          <ReviewNewExternalPatientRecord
            patientRecords={patientRecords}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      )}
      {state?.type === "mergedExternalPatientLink" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.close")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.patientHome(state.rootPatientId), {
                replace: true,
              });
            }}
          />
        </Stack>
      )}
      {state?.type === "not-found" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.backSearch")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.patientSearch(), { replace: true });
            }}
          />
        </Stack>
      )}
      {state?.type === "error" && (
        <Stack direction="row" justifyContent={"center"}>
          <Button
            label={t("buttonLabels.home")}
            testId="close-button"
            onClick={() => {
              navigate(routeFns.home(), { replace: true });
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}
