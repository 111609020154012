import {
  FormContextData,
  formHasSignatures,
  formIsRecordOfDetention,
  getLatestVersion,
} from "@aspire/common";
import { Box } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { Banner, BannerList } from "~/components/design-system/index.js";
import { LoggedInUserContext } from "../../../Contexts.js";
import { routeFns } from "../../../routes.js";
import { createDraftAndHandleResult } from "../admission/helpers/index.js";
import { admissionFormContextLogic } from "../helpers/formContextLogic.js";

export const Section4Banners = ({
  formContext,
}: {
  formContext: FormContextData;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userContext = useContext(LoggedInUserContext);
  const admissionData = admissionFormContextLogic(
    formContext,
    userContext?.user!,
  );
  const newDraftId = useMemo(v4, []);

  // Record of detention logic
  const signedPartOneRecordOfDetention = getLatestVersion(
    formContext.forms.find(
      (f) => formIsRecordOfDetention(f) && formHasSignatures(f),
    ),
  );
  const recordOfDetentionSection = signedPartOneRecordOfDetention?.data?.[0]
    ?.data?.sectionValue as "2" | "3" | "4" | undefined;
  const recordOfDetentionHasExpired =
    signedPartOneRecordOfDetention?.status === "complete" &&
    signedPartOneRecordOfDetention?.signatures.length === 1;

  const { myFormWorkItem, canLaunchDraft, draftIdOnNextPart } =
    admissionData.formData.get(signedPartOneRecordOfDetention?.formId || "") ??
    {};

  const hasDraft = !!draftIdOnNextPart;
  const currentUserIsAssigned =
    formContext.activeTeamworkWorkItem?.assignedUserId ===
    userContext?.user!.id;

  // Don't show banner if any of the following conditions are met
  if (!signedPartOneRecordOfDetention) return null;
  if (recordOfDetentionSection !== "4") return null;
  if (!currentUserIsAssigned) return null;

  // Show "Section 4 Active" banner if a section 4 H3 Part 1 has been completed
  if (recordOfDetentionHasExpired) {
    return (
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={t("pages.formPage.section4ExpiredBannerTitle")}
          body={t("pages.formPage.section4ExpiredBannerBody")}
        />
      </Box>
    );
  } else if (hasDraft || (canLaunchDraft && myFormWorkItem)) {
    return (
      <Box sx={{ mb: 2 }}>
        <Banner
          bannerType={BannerList.WARNING}
          title={t("pages.formPage.section4ActiveBanner")}
          onRightButton={
            hasDraft
              ? () =>
                  navigate(
                    routeFns.formDraftsComplete(
                      draftIdOnNextPart,
                      formContext.patientId,
                    ),
                  )
              : async () => {
                  createDraftAndHandleResult(
                    newDraftId,
                    signedPartOneRecordOfDetention?.formId,
                    navigate,
                    formContext,
                  );
                }
          }
          rightButtonLabel={t("buttonLabels.continue")}
        />
      </Box>
    );
  }

  return null;
};
