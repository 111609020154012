import { ExtendedWorkItem, WorkItemAction } from "@aspire/common";

import {
  Box,
  Chip,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { css } from "@emotion/react";

import {
  Banner,
  BannerList,
  Button,
  calculateWorkItemStatus,
  MenuFlyout,
  MenuOptionsType,
  PopupDialog,
  PopupDialogTitle,
} from "~/components/design-system/index.js";

import { applicationTemplateIds, getBaseFormTemplate } from "@aspire/common";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api.js";
import { routeFns } from "../../routes.js";

import { getHeadingOrSubtitle } from "@aspire/common";
import { useGetNhsNumber } from "~/hooks/ExternalPatientLink/useGetNhsNumber.js";

function buildWorkItemActions(
  workItem: ExtendedWorkItem & { actions: WorkItemAction[] },
  navigate: (path: string) => void,
  pathname: string,
  handleArchiveClick: () => void,
): MenuOptionsType[] {
  const formContextId = workItem.formContextId!;
  const patientId = workItem.patient.id;

  const showView = !pathname.startsWith(
    routeFns.formContextPage(formContextId, patientId),
  );

  return [
    ...workItem.actions
      .filter((action) => !("formId" in action))
      .filter((action) => !(action.type === "view" && !showView))
      .map((action) => {
        switch (action.type) {
          case "view":
            return {
              icon: "view",
              link: routeFns.formContextPage(formContextId, patientId),
              name: "Open",
              disabled: false,
            };

          case "view-patient":
            if (workItem.status !== "pending") {
              return {
                icon: "person",
                link: routeFns.patientHome(patientId),
                name: "View Patient",
                disabled: false,
              };
            }
            return null;
          case "accept":
            return {
              icon: "accept",
              link: routeFns.formReview(formContextId, patientId),
              name: "Review Form & Accept/Decline",
              disabled: false,
            };

          case "claim":
            return {
              icon: "accept",
              onClick: async () => {
                const { data } = await api.work.claim(workItem.id);
              },
              name: "Claim",
              disabled: false,
            };

          case "un-claim":
            return {
              icon: "transfer",
              onClick: async () => {
                const { data } = await api.work.unClaim(workItem.id);
              },
              name: "Un-Claim",
              disabled: false,
            };

          case "archive":
            return {
              icon: "transfer",
              onClick: async () => {
                if (workItem.status !== "completed")
                  return handleArchiveClick();
                const { data } = await api.work.archive(workItem.id);
              },
              name: "Archive",
              disabled: false,
            };

          case "un-archive":
            return {
              icon: "transfer",
              onClick: async () => {
                const { data } = await api.work.unArchive(workItem.id);
              },
              name: "Unarchive",
              disabled: false,
            };

          default:
            return {
              icon: "cancel",
              onClick: async () => {},
              name: "UNIMPLEMENTED (talk to david): " + action.type,
              disabled: false,
            };
        }
      }),
  ].filter((option) => option !== null) as MenuOptionsType[];
}

export const StatusTableCells = ({
  item,
  showNhsNumber,
}: {
  item: ExtendedWorkItem & { actions: WorkItemAction[] };
  showNhsNumber: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const nhsNumber = useGetNhsNumber({ patientId: item.patient.id });

  const handleArchiveClick = () => setShowPopup(true);

  const mainFormTemplate =
    item?.formContextType === "standalone"
      ? getBaseFormTemplate(
          item?.forms[0].formTemplate.id,
          item?.forms[0].formTemplate.version,
        )
      : null;

  const options = buildWorkItemActions(
    item,
    navigate,
    pathname,
    handleArchiveClick,
  );

  const applicationAdmissionForm = item.forms.find(
    (f) =>
      ["complete", "finalised"].includes(f.status) &&
      applicationTemplateIds.includes(f.formTemplate.id),
  );

  const applicationTemplate = applicationAdmissionForm
    ? getBaseFormTemplate(
        applicationAdmissionForm.formTemplate.id,
        applicationAdmissionForm.formTemplate.version,
      )
    : null;

  const subtitle: string = getHeadingOrSubtitle(
    item.formContextType,
    mainFormTemplate,
    applicationTemplate,
    false,
  );

  const { t } = useTranslation();
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const cardRef = useRef(null);

  const formattedDob = item.patient.dateOfBirth
    ? dayjs(item.patient.dateOfBirth).format("DD/MM/YYYY")
    : t("common.unknown");

  const summary = calculateWorkItemStatus(theme, item);

  const isNotCompleteStatus = item.status !== "completed";

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell
            align="left"
            sx={{
              width: "10%",
            }}
          >
            <Box display="flex" alignItems="center">
              <Link
                style={{
                  textDecoration: "none",
                }}
                to={routeFns.patientHome(item.patient.id)}
              >
                <Typography
                  sx={{
                    textDecoration: "none",
                    fontSize: "15px",
                    backgroundColor: "transparent",
                    outline: "none",
                    color: "blue",
                  }}
                  variant="subtitle1"
                >
                  {item.patient.name.family}, {item.patient.name.given}
                </Typography>
              </Link>
            </Box>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: "10%",
            }}
          >
            <Typography variant="subtitle2">
              <Chip label={formattedDob} size="small" />
            </Typography>
          </TableCell>
          {showNhsNumber && (
            <>
              <TableCell
                align="left"
                sx={{
                  width: "10%",
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1">
                    {nhsNumber ?? t("common.unknown")}
                  </Typography>
                </Box>
              </TableCell>
            </>
          )}
          <TableCell
            align="left"
            sx={{
              width: "40%",
            }}
          >
            <Link
              style={{
                textDecoration: "none",
              }}
              to={routeFns.formContextPage(item.formContextId, item.patient.id)}
            >
              <Typography
                sx={{
                  textDecoration: "none",
                  fontSize: "15px",
                  backgroundColor: "transparent",
                  outline: "none",
                  color: "blue",
                }}
                variant="subtitle1"
              >
                {subtitle}
              </Typography>
            </Link>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: "20%",
            }}
          >
            <WorkItemStatusBox
              color={theme.palette.common.black}
              backgroundColor={summary.colour}
              headingText={"Summary"}
              mainText={summary.statusText}
              align={"flex-start"}
              hideHeadingText={true}
            />
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: "40%",
            }}
          >
            <Box>
              {isNotCompleteStatus &&
                (item.assignedUserId ? (
                  <WorkItemStatusBox
                    color={theme.palette.text.primary}
                    backgroundColor={theme.palette.statuses?.teal}
                    headingText={t("components.workItemCard.claimedStatus")}
                    mainText={item.assignedUser!.name}
                    hideHeadingText={true}
                  />
                ) : (
                  <WorkItemStatusBox
                    color={theme.palette.common.black}
                    backgroundColor={theme.palette.statuses.yellow}
                    headingText={t("components.workItemCard.claimedByStatus")}
                    mainText={t("components.workItemCard.notYetClaimedStatus")}
                    hideHeadingText={true}
                  />
                ))}
            </Box>
          </TableCell>
          <TableCell
            align="center"
            sx={{
              width: "10%",
            }}
          >
            <Box ref={cardRef} onClick={() => setIsMainMenuOpen(true)}>
              <Flyout
                setIsMainMenuOpen={setIsMainMenuOpen}
                cardRef={cardRef}
                isMainMenuOpen={isMainMenuOpen}
                options={options}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>

      <PopupDialog open={showPopup} onClose={() => setShowPopup(false)}>
        <Box sx={{ p: 4 }}>
          <PopupDialogTitle
            titleText={t("pages.caseload.popupDialog.popArchiveTitle")}
            closeDialog={() => setShowPopup(false)}
          />
          <Banner
            bannerType={BannerList.INFO}
            title={t("pages.caseload.popupDialog.popupWarningText")}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt: 4,
            }}
          >
            <Button
              variant="outlined"
              label={t("buttonLabels.close")}
              onClick={() => setShowPopup(false)}
            />
            <Button
              label={t("buttonLabels.archive")}
              onClick={async () => {
                const { data } = await api.work.archive(item.id);
                setShowPopup(false);
              }}
            />
          </Box>
        </Box>
      </PopupDialog>
    </>
  );
};

export function WorkItemStatusBox({
  headingText,
  mainText,
  backgroundColor,
  color,
  align,
  hideHeadingText,
}: {
  headingText: string;
  mainText: string | string[];
  backgroundColor: string;
  color: string;
  align?: "flex-start" | "flex-end";
  hideHeadingText?: boolean;
}) {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {!hideHeadingText && (
        <Typography
          css={css`
            font-size: ${theme.spacing(1.625)};
            color: ${theme.palette.primary.hint};
            margin-bottom: ${theme.spacing(0.5)};
            justify-content: ${align || "flex-start"};
            display: flex;
          `}
        >
          {headingText}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            padding: theme.spacing(0.75),
            borderRadius: "6px",
            backgroundColor,
            color,
          }}
        >
          <Typography
            css={css`
              font-size: ${theme.spacing(1.625)};
              background-color: ${backgroundColor};
            `}
          >
            {typeof mainText === "string"
              ? mainText
              : mainText.map((t) => (
                  <>
                    {t}
                    <br />
                  </>
                ))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Flyout({
  setIsMainMenuOpen,
  cardRef,
  isMainMenuOpen,
  options,
}: {
  setIsMainMenuOpen: (params: boolean) => void;
  cardRef: any;
  isMainMenuOpen?: boolean;
  options: MenuOptionsType[];
}) {
  return (
    <Box display="flex">
      <Box
        css={css`
          display: flex;
        `}
        onClick={(e) => {
          e.stopPropagation();
          setIsMainMenuOpen(!isMainMenuOpen);
        }}
      >
        {!!options?.length && (
          <MenuFlyout
            cardRef={cardRef}
            options={options}
            isOpen={isMainMenuOpen}
            onClose={() => setIsMainMenuOpen(false)}
          />
        )}
      </Box>
    </Box>
  );
}
